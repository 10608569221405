var lang = document.getElementsByTagName("body")[0].className.match(/lang-fr/) ? 'fr' : 'en';

var termPrev = (lang == 'fr') ? "Précédent" : "Previous";
var termNext = (lang == 'fr') ? "Suivant" : "Next";

// Elisys Object Wrapper for Sliders
var oElisysSliders = {
  bDebug : false,
  aRefs: [],
  aOptions: [],

  // Create a new SlickJS instance for a given selector,
  // using the ImagesLoaded bower library
  createNewSlick: function(sRef, sSelector) {

    // Do not instantiate SlickJS twice on the same object
    if( oElisysSliders.aRefs[sRef] && oElisysSliders.aRefs[sRef].hasClass('slick-slider') ) {
      return;
    }

    // Wait for the first image to be fully loaded, then instantiate SlickJS
    jQuery(sSelector + ' img:first').imagesLoaded()
      .done( function( instance ) {

        // Get SlickJS Options
        var oSlickOptions = oElisysSliders.getSlickOptions(sRef);

        // Instantiate SlickJS and keep its reference (jQuery object)
        oElisysSliders.aRefs[sRef] = jQuery(sSelector).slick( oSlickOptions );

        // Add property to keep in memory, the original selector to help refreshSlick function
        oElisysSliders.aRefs[sRef].sOriginalSelector = sSelector;

        oElisysSliders.aRefs[sRef].parent().css('height', '' );

        // If slickJS "adaptiveHeight" option is set AND true, bind events helping lazy-loading to not break the visual
        if( oSlickOptions.adaptiveHeight && oSlickOptions.adaptiveHeight === true && oSlickOptions.lazyLoad )
        {
          oElisysSliders.bindEventSlickChange(sRef);
        }
      });
  },

  // Remove SlickJS instanciation
  removeSlick: function(sRef) {

    // Validate object reference
    if( oElisysSliders.aRefs[sRef] ) {

      // Validate SlickJS instanciation
      if( oElisysSliders.aRefs[sRef].hasClass('slick-slider') ) {
        oElisysSliders.aRefs[sRef].slick('unslick');
      }

      delete oElisysSliders.aRefs[sRef];
    }

  },

  // Shortcut function to remove, then create a SlickJS without need of having selector
  // NB: It should require a new arguments (HTML) to append into the selector container
  refreshSlick: function(sRef) {

    var sSelector = oElisysSliders.aRefs[sRef].sOriginalSelector;

    oElisysSliders.removeSlick(sRef);

    oElisysSliders.createNewSlick(sRef, sSelector);

  },

  // Return a SlickJS object properties
  getSlickOptions: function(sRef) {

    // Default settings
    var oReturn = {
      lazyLoad: 'progressive',
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="' + termPrev + '" role="button" style="display: block;"><span>' + termPrev + '</span></button>',
      nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="' + termNext + '" role="button" style="display: block;"><span>' + termNext + '</span></button>',
      fade: true,
      // asNavFor: '#featColors .gallery-nav .slider',
      dots: false
    };

    switch(sRef) {
      case "home_slider":

        oReturn = {
          //lazyLoad: 'ondemand',
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          speed: 750,
          prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="' + termPrev + '" role="button" style="display: block;"><span>' + termPrev + '</span></button>',
          nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="' + termNext + '" role="button" style="display: block;"><span>' + termNext + '</span></button>',
          appendArrows: jQuery('#slider .controls-arrows .inner'),
          dots: true,
          appendDots: jQuery('#slider .controls-dots .inner'),
          fade: true,
          responsive : [
            {
              breakpoint: 992,
              settings: {
                autoplay: false,
                fade: false
              }
            }
          ]
        };

        break;

      case "realisation_photos_big":

        oReturn = {
          lazyLoad: 'progressive',
          adaptiveHeight: true,
          speed: 100,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="' + termPrev + '" role="button" style="display: block;"><span>' + termPrev + '</span></button>',
          nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="' + termNext + '" role="button" style="display: block;"><span>' + termNext + '</span></button>',
          fade: true,
          asNavFor: '#realisation-photos .gallery-nav .slider'
        };

        break;

      case "realisation_photos_nav":

        oReturn = {
          lazyLoad: 'progressive',
          adaptiveHeight: true,
          slidesToShow: 6,
          slidesToScroll: 1,
          prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="' + termPrev + '" role="button" style="display: block;"><span>' + termPrev + '</span></button>',
          nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="' + termNext + '" role="button" style="display: block;"><span>' + termNext + '</span></button>',
          asNavFor: '#realisation-photos .gallery .slider',
          dots: false,
          centerMode: false,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2
              }
            }
          ]
        };

        break;

    }

    return oReturn;
  },

  // Bind SlickJS events keeping the gallery height when next image is load with lazy-loading
  bindEventSlickChange: function (sRef) {

    // Before changing for the next image, remove AdaptiveHeight behavior
    oElisysSliders.aRefs[sRef].on('beforeChange', function(slick, currentSlide, nextSlide){
        jQuery(this).slick('slickSetOption', 'adaptiveHeight', false);
      });

    // After the image is loaded, reset AdaptiveHeight behavior
    oElisysSliders.aRefs[sRef].on('afterChange', function(slick, currentSlide){
        jQuery(this).slick('slickSetOption', 'adaptiveHeight', true);
      });
  },

  // Special function for LamborghiniMtl, used when a new color is choosen by the user
  // NB: It needs to be improve, or included into the "refreshSlick" function
  setNewSlickImages: function(sRef, sSelectorNewListImages, sSourceHtml) {

    var $jqContainerGallery = oElisysSliders.aRefs[sRef].parent();

    // Set static temporary height for ".gallery" container
    $jqContainerGallery.css('height', $jqContainerGallery.css('height') );

    $jqContainerGallery.animate({ opacity: 0 }, 200, function() {
      // Remove Slick instance
      oElisysSliders.aRefs[sRef].slick('unslick');

      // NEEDS CODE REVIEW
      // Remove ".slider" container, then append it again?!?!?
      $jqContainerGallery.find('.slider:first').remove();

      $jqContainerGallery.prepend("<div class='slider'></div>");

      var sInsertHtml = '';
      if( sSelectorNewListImages )
      {
        // Generate the new HTML, fetching the hidden sliders image source list
        var sImgAttributeSource = 'src';
        jQuery(sSelectorNewListImages).each( function(iIndex){
          sInsertHtml += '<div><img ' + sImgAttributeSource + '="' + jQuery(this).text() + '" ></div>';
          sImgAttributeSource = 'data-lazy';
        });
      }
      else if( sSourceHtml )
      {
        sInsertHtml = sSourceHtml;
      }


      // Append the new HTML
      $jqContainerGallery.find('.slider:first').append(sInsertHtml);

      // Refresh slick component
      oElisysSliders.refreshSlick(sRef);


      $jqContainerGallery.animate({ opacity: 1 }, 300);
    });

  },

  refreshSlickUI: function(sRef){
    if( oElisysSliders.aRefs[sRef] )
    {
      oElisysSliders.aRefs[sRef].parent().css('height', '' );
      oElisysSliders.aRefs[sRef].slick('setOption', '', '', true);
    }
  }

};

